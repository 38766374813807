import { createTRPCReact } from "@trpc/react-query";
import { type inferRouterInputs, type inferRouterOutputs } from "@trpc/server";

import { type AppRouter } from "@matan/webapi";

export const trpc = createTRPCReact<AppRouter>({
  overrides: {
    useMutation: {
      async onSuccess(opts) {
        // Calls the `onSuccess` defined in the `useQuery()`-options:
        const res = (await opts.originalFn()) as {
          invalidateAllCachedQueries?: boolean;
        };
        // Invalidate all queries in the react-query cache. Default behavior is to do this.
        if (!res || res.invalidateAllCachedQueries)
          await opts.queryClient.invalidateQueries();
      },
    },
  },
});

export type RouterInput = inferRouterInputs<AppRouter>;
export type RouterOutput = inferRouterOutputs<AppRouter>;
